/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Questionnaire.module.css";

export default function Questionnaire(props) {
  const { t } = useTranslation();

  const [selected, setSelected] = useState({});
  const [isQuestionnaireError, setIsQuestionnaireError] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  
  const [questions, setQuestions] = useState(props.questions || [
    {
      question: { title: t('vb_questionnaire_q_decision'), name: 'decision' },
      answers: [
        { title: t('vb_questionnaire_q_decision_yes'), score: 10, option: 'a' },
        { title: t('vb_questionnaire_q_decision_not_decided'), score: 7, option: 'b' },
        { title: t('vb_questionnaire_q_decision_no'), score: 2, option: 'c' },
        { title: t('vb_questionnaire_q_decision_sell_first'), score: 5, option: 'd' },
      ]
    },
    {
      question: { title: t('vb_questionnaire_q_familiar'), name: 'familiar' },
      answers: [
        { title: t('vb_questionnaire_q_familiar_yes'), score: 10, option: 'a' },
        { title: t('vb_questionnaire_q_familiar_general'), score: 5, option: 'b' },
        { title: t('vb_questionnaire_q_familiar_no'), score: 2, option: 'c' },
        { title: t('vb_questionnaire_q_familiar_need_info'), score: 7, option: 'd' },
      ]
    },
    {
      question: { title: t('vb_questionnaire_q_price'), name: 'price' },
      answers: [
        { title: t('vb_questionnaire_q_price_yes'), score: 7, option: 'a' },
        { title: t('vb_questionnaire_q_price_rough'), score: 5, option: 'b' },
        { title: t('vb_questionnaire_q_price_need'), score: 3, option: 'c' },
        { title: t('vb_questionnaire_q_price_no'), score: 8, option: 'd' },
      ]
    },
    {
      question: { title: t('vb_questionnaire_q_welcome'), name: 'welcome' },
      answers: [
        { title: t('vb_questionnaire_q_welcome_yes'), score: 10, option: 'a' },
        { title: t('vb_questionnaire_q_welcome_prepare'), score: 7, option: 'b' },
        { title: t('vb_questionnaire_q_welcome_not_now'), score: 4, option: 'c' },
        { title: t('vb_questionnaire_q_welcome_not_ready'), score: 2, option: 'd' },
      ]
    },
    {
      question: { title: t('vb_questionnaire_q_experience'), name: 'experience' },
      answers: [
        { title: t('vb_questionnaire_q_experience_yes'), score: 10, option: 'a' },
        { title: t('vb_questionnaire_q_experience_yes_once'), score: 8, option: 'b' },
        { title: t('vb_questionnaire_q_experience_no'), score: 7, option: 'c' },
        { title: t('vb_questionnaire_q_experience_no_plan'), score: 0, option: 'd' },
      ]
    },
    {
      question: { title: t('vb_questionnaire_q_important'), name: 'important' },
      answers: [
        { title: t('vb_questionnaire_q_important_yes'), score: 10, option: 'a' },
        { title: t('vb_questionnaire_q_important_moderate'), score: 5, option: 'b' },
        { title: t('vb_questionnaire_q_important_try'), score: 2, option: 'c' },
        { title: t('vb_questionnaire_q_important_exploring'), score: 0, option: 'd' },
      ]
    }
  ]);

  useEffect(() => {
    setQuestions(questions.map((question, index) => ({...question, displayed: !index, selected: null})))
  }, [])

  useEffect(() => {
    handleIsFormValid();
  }, []);

  function handleClick(name, item) {
    const data = {}
    data[name] = item
    
    setIsSelected(true)
    setSelected({ ...selected, ...data })
  }

  useEffect(() => {
    setIsQuestionnaireError(Object.keys(selected).length !== questions.length)
  }, [selected]);

  useEffect(() => {
    handleIsFormValid();
  }, [isQuestionnaireError]);

  function handleNext() {
    let isUpdated = false;

    const score = Object.values(selected).reduce((a, b) => a + b.score, 0)
    const qa = Object.values(selected).map((d, k) => ({question: (k + 1), option: d.option, score: d.score}))

    const data = {
      questionnaire_qa: JSON.stringify(qa),
      questionnaire_score: score,
      completed: false
    };

    props.submit(data);

    setQuestions(prevQuestions => 
      prevQuestions.map((q) => {
        if (!isUpdated && !q.displayed) {
          isUpdated = true;
          return {...q, displayed: true};
        }

        return q;
      })
    )

    setIsSelected(false)
  }

  function handleSubmit() {
    const matchAnswer = (value, answer) => value.option === answer

    const score = Object.values(selected).reduce((a, b) => a + b.score, 0)
    const negativeAnswer = matchAnswer(selected.welcome, 'd') ||
      matchAnswer(selected.experience, 'd') ||
      matchAnswer(selected.important, 'd')
    const qa = Object.values(selected).map((d, k) => ({question: (k + 1), option: d.option, score: d.score}))

    const data = {
      questionnaire_qa: JSON.stringify(qa),
      questionnaire_score: score,
      negativeAnswer,
      completed: true
    };

    props.submit(data);
  }

  const SliderBar = () => {
    return (
      <div className={styles.slidebarContainer}>
        {questions.map((q, key) => (
          <div key={key} className={`${styles.slidebar} ${q.displayed ? styles.slidebarActive : ''}`}></div>
        ))}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className="custom-popup__descr-container">
        <div className={`${styles.customPopup} custom-popup__descr custom-popup__seventh-descr`}>
          <div className="custom-popup__descr-title-thin">{t('vb_howfast_answer_questions')}</div>
          <div className="custom-popup__descr-subtitle-thin">{t('vb_howfast_question_assess')}</div>

          <div className="mt-4">
            {questions.filter(item => item.displayed).slice(-1).map((item, key) => (
              <p key={key} className={styles.questionText}>{ item.question.title }</p>
            ))}

            <SliderBar />

            {questions.filter(item => item.displayed).slice(-1).map((item, key) => (
              <div key={key} >
                {item.answers.map((answer, i) => (
                  <label key={i} className={`radio-container ${((item.question.name in selected) && selected[item.question.name].option) === answer.option ? 'selected' : ''}`}>
                    <input
                      type="radio"
                      name={item.question.name}
                      value={answer.option}
                      onClick={() => handleClick(item.question.name, answer)}
                    />
                    <span className="radio" />
                    <span className={styles.radioContainerText}>{answer.title}</span>
                  </label>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div>
          {questions.filter(item => !item.displayed).length > 0 ?
            <button
              type="button"
              className="btn btn-primary w-100"
              disabled={!isSelected}
              onClick={() => handleNext()}
            >{t('button_next')}</button> :
            <button
              type="button"
              className="btn btn-primary w-100"
              disabled={!isFormValid}
              onClick={() => handleSubmit()}
            >{t('Confirm')}</button>
          }
        </div>
      </div>
    </div>
  );

  function handleIsFormValid() {
    if (isQuestionnaireError) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);
  }
}

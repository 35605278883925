import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import store from "./App/store";
import './i18n';
import { HelmetProvider } from "react-helmet-async";

import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { AppPropsContext } from "./context";
import LoadingCircle from "./components/LoadingCircle/LoadingCircle";
import Pricing from "./screens/Pricing/Pricing";
import Questionnaire from "./components/Questionnaire/Questionnaire";

const App = lazy(() => import("./App"));
const Header = lazy(() => import("./components/v10/Header/Header"));
const Footer = lazy(() => import("./components/v10/Footer/Footer"));
const CookiePopup = lazy(() => import("./components/CookiePopup/CookiePopup"));
const Professionals = lazy(() => import("./screens/Professionals/Professionals"));
const AlertPopup = lazy(() => import("./components/AlertPopup/AlertPopup"));
const Prequalification = lazy(() => import("./components/Prequalification/Prequalification"));
const BuyerServices = lazy(() => import("./screens/Services/Services"));
const AddressConfirm = lazy(() => import("./components/AddressConfirm/AddressConfirm"));
const SeeMore = lazy(() => import("./components/VBPopup/SeeMore/SeeMore"));
const DiscountCoupon = lazy(() => import("./components/DiscountCoupon/DiscountCoupon"));
const ValuationScore = lazy(() => import("./components/ValuationScore/ValuationScore"));
const OfferScreen = lazy(() => import("./components/OffMarketOffer/OfferScreen/OfferScreen"));
const HowFast = lazy(() => import("./components/HowFast/HowFast"));
const CommCalc = lazy(() => import("./components/CommCalc/CommCalc"));

function render(container, component) {
  if (!container) {
    return;
  }

  const root = createRoot(container);
  root.render(component);
}

window.renderProfessionals = (props) => {
  render(
    document.getElementById("professionals_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <Professionals {...props} />
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderCookiePopup = (props) => {
  render(
    document.getElementById("cookie_popup"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <CookiePopup {...props} />
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderAlertPopup = (container, props) => {
  render(
    container,
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <AlertPopup {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
}

window.renderPrequalification = (container, props) => {
  render(
    container,
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <Prequalification {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
}

window.renderAddressConfirm = (container, props) => {
  render(
    container,
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <AddressConfirm {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
}

window.renderVBPopupSeeMore = (container, props) => {
  render(
    container,
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <SeeMore {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
}

window.renderHowFast = (container, props) => {
  render(
    container,
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <HowFast {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
}

window.renderQuestionnaire = (container, props) => {
  render(
    container,
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <Questionnaire {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
}

window.renderBuyerServices = (props) => {
  render(
    document.getElementById("buyer_services_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <BuyerServices {...props} />
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderDiscountCoupon = (props) => {
  // TODO: Remove DiscountCoupon after 2022-12-31
  function ShowDiscountCoupon() {
    return (new Date() < new Date('2022-12-31')) && (
      <DiscountCoupon {...props} />
    );
  }

  render(
    document.getElementById("discount_coupon_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <ShowDiscountCoupon></ShowDiscountCoupon>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderUsersValuationScore = (props) => {
  render(
    document.getElementById("users_valuation_score_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <ValuationScore {...props} />
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderCommCalc = (props) => {
  render(
    document.getElementById("comm_calc_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <div className="v10">
            <CommCalc {...props} />
          </div>
        </Suspense>
      </React.StrictMode>
    </Provider>    
  );
};

window.renderOffMarketOfferScreen = (props) => {
  render(
    document.getElementById("off_market_offer_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <OfferScreen {...props} />
        </Suspense>
      </React.StrictMode>
    </Provider>    
  );
};

window.renderPricingScreen = (props) => {
  render(
    document.getElementById("subscription_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <Pricing {...props} />
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderHeader = (props) => {
  render(
    document.getElementById("header_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <AppPropsContext.Provider value={{ ...(window.appProperties || {}) }}>
            <Header {...props} />
          </AppPropsContext.Provider>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

window.renderFooter = (props) => {
  render(
    document.getElementById("footer_container"),
    <Provider store={store}>
      <React.StrictMode>
        <Suspense fallback={<></>}>
          <AppPropsContext.Provider value={{ ...(window.appProperties || {}) }}>
            <Footer {...props} />
          </AppPropsContext.Provider>
        </Suspense>
      </React.StrictMode>
    </Provider>
  );
};

const app = document.getElementById('app');
if (app) {
  const root = createRoot(app);
  root.render(
    <Provider store={store}>
      <React.StrictMode>
        <Router>
          <Suspense fallback={
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: '100vh' }}
            >
              <LoadingCircle />
            </div>
          }>
            <CookiePopup />
            <ScrollToTop />
            <AppPropsContext.Provider value={{ ...(window.appProperties || {}) }}>
              <HelmetProvider>
                <App />
              </HelmetProvider>
            </AppPropsContext.Provider>
          </Suspense>
        </Router>
      </React.StrictMode>
    </Provider>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
